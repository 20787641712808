/* eslint-disable axo-frontend/directory-structure */
import { ContentBox, Hr, Icons } from '@axo/deprecated/util/ui-components';
import {
  InsuranceFeatureFlags,
  StepContext,
  useCreateInsuranceEvent,
  type InsuranceFeatureFlagsValues,
} from '@axo/insurance/feature/providers';
import { ActionBar, NavWrapper, StepNavigator } from '@axo/insurance/ui';
import { MyPageFlags } from '@axo/mypage/util';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { classNames } from '@axo/shared/util/dom';
import { useContext, useMemo } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Explainer.module.scss';
import { ExplainerContentA, ExplainerContentB } from './ExplainerContent';

type TExplainerProps = {
  onAcceptInsurance?: () => void;
  onDeclineInsurance?: () => void;
};

export const Explainer = ({
  onAcceptInsurance,
  onDeclineInsurance,
}: TExplainerProps) => {
  const { dispatch, state, onEnd, isEmbedded } = useContext(StepContext);
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { t } = useTranslation();
  const log = useEventLogger();
  const addEvent = useCreateInsuranceEvent();
  const { track } = useAnalytics();
  let {
    flag_ins_step_header_position,
    flag_ins_explainer_cta_position,
    flag_ins_explainer_content,
  } = useFlags(InsuranceFeatureFlags) as InsuranceFeatureFlagsValues;
  useFlagImpression(InsuranceFeatureFlags, {
    flag_ins_step_header_position,
    flag_ins_explainer_content,
    flag_ins_explainer_cta_position,
  });
  flag_ins_step_header_position ??= 'top';
  flag_ins_explainer_cta_position ??= 'horizontal';
  flag_ins_explainer_content ??= 'B';
  useEventField('current-step', 'explainer');

  const { 'new-insurance-flow-v2': flag_new_insurance_flow_v2 } =
    useFlags(MyPageFlags);

  const {
    steps: { explainer: stepData },
  } = state;

  if (!['completed', 'active'].includes(stepData.state)) {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'active' },
    });
  }
  function handleInsuranceAccept() {
    if (flag_new_insurance_flow_v2 && onAcceptInsurance) {
      onAcceptInsurance();
      return;
    }
    log(EventCode.InsuranceNext);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'completed' },
    });
    track({
      event: `Insurance Step ${stepData.name} Completed`,
    });

    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'selectCoverage',
    });
  }

  async function handleInsuranceDecline() {
    if (flag_new_insurance_flow_v2 && onDeclineInsurance) {
      onDeclineInsurance();
      return;
    }
    log(EventCode.InsuranceDeclined);
    addEvent('no/form/insurance-declined', 'explainer');
    await track({
      event: 'Insurance Declined',
      options: { send_immediately: true },
      params: { step: 'explainer' },
    });
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;

    onEnd.action({ token, uid });
  }

  const contentBoxProps = useMemo(() => {
    if (flag_new_insurance_flow_v2)
      return {
        classes: {
          root: stepStyles.contentContainerV2,
          tagContainer: styles.tagContainer,
        },
      };

    return {
      tagText: t('Popular'),
      classes: {
        root: stepStyles.contentContainer,
        tag: styles.recommendedTag,
      },
    };
  }, [flag_new_insurance_flow_v2, t]);

  return (
    <>
      <ContentBox {...contentBoxProps}>
        <div
          className={classNames(
            styles.shield,
            flag_new_insurance_flow_v2 && styles.shieldV2
          )}
        >
          {flag_new_insurance_flow_v2 ? (
            <>
              <div className={styles.shieldLeftContent}>
                <Icons.Shield size="lg" />
                <h3 className={styles.productHeader}>
                  {t('Axo Laina Insurance')}
                </h3>
              </div>
              <div className={styles.recommendedTag}>{t('Popular')}</div>
            </>
          ) : (
            <>
              <Icons.Shield size="lg" />
              <h3 className={styles.productHeader}>
                {t('Axo Laina Insurance')}
              </h3>
            </>
          )}
        </div>

        {!flag_new_insurance_flow_v2 &&
          (flag_ins_step_header_position === 'inside' ? (
            <h4 className={styles.productSubHeader}>{t('A safe choice')}</h4>
          ) : (
            <Hr className={stepStyles.horizontalSeparator} />
          ))}

        <div
          className={classNames(
            styles.content,
            flag_new_insurance_flow_v2 && styles.contentV2
          )}
        >
          {flag_ins_explainer_content === 'A' ? (
            <ExplainerContentA />
          ) : (
            <ExplainerContentB />
          )}
        </div>
      </ContentBox>
      <NavWrapper
        alwaysStickyNavButtons={isEmbedded}
        className={flag_new_insurance_flow_v2 ? styles.navWrapper : undefined}
      >
        {flag_new_insurance_flow_v2 ? (
          <ActionBar
            backButton={{
              text: t('Continue with insurance'),
              onClick: handleInsuranceAccept,
              variant: 'primary',
              classes: styles.button,
            }}
            nextButton={{
              text: t('Sign loan agreement'),
              onClick: handleInsuranceDecline,
              classes: styles.button,
            }}
          />
        ) : (
          <StepNavigator
            layout={flag_ins_explainer_cta_position}
            leftButton={{
              width: 'standard',
              variant: 'secondary',
              text: t('Maybe later'),
              onClick: () => handleInsuranceDecline(),
            }}
            rightButton={{
              width: 'standard',
              variant: 'enabled',
              text: t('Next'),
              onClick: () => handleInsuranceAccept(),
            }}
          />
        )}
      </NavWrapper>
    </>
  );
};
