import { IPayOutAndPremium, payoutAndPremium } from '@axo/insurance/const/no';
import { useEffect, useRef } from 'react';
import { currencyFormatter } from '../../currency';
import { useTranslation } from '../../translations/useTranslation';
import styles from './AmountSelectionMenu.module.scss';

export default ({
  onChange,
}: {
  onChange: (payout: IPayOutAndPremium | null) => void;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onChange(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onChange]);

  return (
    <div className={styles.popupMenu} ref={ref}>
      {payoutAndPremium.map((payout) => {
        return (
          <button
            className={styles.popupMenuButton}
            onClick={() => onChange(payout)}
            key={payout.payout}
          >{`${currencyFormatter(payout.payout)}/${t('Month')}`}</button>
        );
      })}
    </div>
  );
};
