import { Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '../../translations/useTranslation';
import styles from './EditButton.module.scss';

export default ({ onClick }: { onClick: (v: boolean) => void }) => {
  const { t } = useTranslation();
  return (
    <button
      className={styles.editButton}
      onClick={() => {
        onClick(true);
      }}
    >
      {t('Edit amount')}
      <Icons.Edit size="sm" color={{ primary: '--info-500' }} />
    </button>
  );
};
