/* eslint-disable axo-frontend/directory-structure */
import { trustpilotConfig } from '@axo/insurance/const/no';
import {
  AppSpecificStepProviderProps,
  StepList,
  StepProvider,
  stepperReducer,
  useInsuranceLogging,
} from '@axo/insurance/feature/providers';
import { ITrustpilot } from '@axo/shared/ui/core';
import { useReducer } from 'react';
import { getInitialStepContextState } from '../InsuranceProvider/storedState';
import { AvtaleGiro } from './steps/AvtaleGiro/AvtaleGiro';
import { Error } from './steps/Error';
import { Explainer } from './steps/Explainer';
import { SelectCoverage } from './steps/SelectCoverage';
import { Summary } from './steps/Summary';
import { Terms } from './steps/Terms';

export const StepProviderNorway = (props: AppSpecificStepProviderProps) => {
  const search = new URLSearchParams(window.location.search);

  const initialState = getInitialStepContextState(
    search,
    props.initialStep,
    props.isEmbedded
  );
  const [state, dispatch] = useReducer(stepperReducer, initialState);
  useInsuranceLogging(state);

  return (
    <StepProvider
      steps={steps}
      state={state}
      dispatch={dispatch}
      locale="no"
      trustpilotConfig={
        trustpilotConfig as Pick<ITrustpilot, 'businessId' | 'locale' | 'link'>
      }
      {...props}
    />
  );
};

const steps: StepList = {
  explainer: <Explainer />,
  selectCoverage: <SelectCoverage />,
  terms: <Terms />,
  summary: <Summary />,
  error: <Error />,
  avtaleGiro: <AvtaleGiro />,
};
