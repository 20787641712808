/* eslint-disable axo-frontend/directory-structure */
import { A, H5, Hr, Icons, P } from '@axo/deprecated/util/ui-components';
import {
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '@axo/insurance/const/no';
import { StepContext } from '@axo/insurance/feature/providers';
import { NavWrapper, PageSpinner, StepNavigator } from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { useAnalytics } from '@axo/shared/services/analytics';
import { classNames } from '@axo/shared/util/dom';
import { useContext, useEffect } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import { currencyFormatter } from '../../currency';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Summary.module.scss';

export const Summary = () => {
  const { state, dispatch, onEnd, isEmbedded } = useContext(StepContext);
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { t } = useTranslation();

  const search = new URLSearchParams(window.location.search);
  const isSuccess = search.get('success');
  const error = search.get('error');

  const log = useEventLogger();
  const { track } = useAnalytics();
  useEventField('current-step', 'summary');
  useEventField('is-payment-successful', isSuccess ?? false.toString());

  useEffect(() => {
    if (!error) return;
    if (isSuccess) return;
    log(EventCode.PaymentFailed, { 'error-message': error });
    dispatch({
      type: 'Set error',
      scope: { parentType: 'error' },
      payload: 'Payment failed',
    });
  }, [dispatch, error, isSuccess, log]);

  async function handleNext() {
    log(EventCode.InsuranceNext);
    await track({
      event: 'Insurance Step summary Completed',
      options: {
        send_immediately: true,
      },
    });
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  if (error || !isSuccess) return <PageSpinner />;

  return (
    <>
      <div className={classNames(styles.contentMargin)}>
        <div className={styles.content}>
          <div className={styles.shield}>
            <Icons.Shield size="md" />
            <h3 className={styles.thankYou}>{t('Thank you')}!</h3>
          </div>
          {t('Congratulations with your insurance')}
        </div>
        <div className={styles.infoBox}>
          <div className={styles.infoHeader}>
            <H5>{t('Insurance information')}</H5>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.infoItem}>
            <span className={styles.value}>
              {t('Will be sent to you by email')}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.infoItem}>
            <P className={styles.label}>{t('Monthly payout')}</P>
            <span className={styles.value}>
              {currencyFormatter(state.steps.selectCoverage.insurance.payout)}/
              {t('Month')}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.infoItem}>
            <P className={styles.label}>{t('Monthly insurance cost')}</P>
            <span className={styles.value}>
              {currencyFormatter(state.steps.selectCoverage.insurance.premium)}/
              {t('Month')}
            </span>
          </div>
          <Hr className={stepStyles.horizontalSeparator} />
          <div className={styles.extraInfo}>
            <p className={styles.label}>{t('Downloadable information')}</p>
            <div className={styles.extraInfoLinkContainer}>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A href={productFactSheetUrl} target="_blank" rel="noreferrer">
                  {t('Product Fact Sheet')}
                </A>
              </div>
              <div className={styles.extraInfoLink}>
                <Icons.Pdf size="sm" />
                <A
                  href={prePurchaseInformationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('Terms and Conditions')}
                </A>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          rightButton={{
            text: t('Continue'),
            variant: 'enabled',
            width: 'full-width',
            onClick: handleNext,
          }}
        />
      </NavWrapper>
    </>
  );
};
