import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';
import styles from './card.module.scss';

type CardProps = {
  children: ReactNode;
  spacing?: 'xxs' | 'small' | 'medium'; // TODO align with general xxs, xs, s, m, l, xl ... (blocked by ui-content package not yet merged)
  variant?: 'default' | 'input';
  className?: string;
  style?: CSSProperties;
};

const CardRoot = ({
  children,
  spacing = 'small',
  variant = 'default',
  className,
  ...props
}: CardProps) => {
  return (
    <div
      className={clsx(styles.card, className)}
      data-spacing={spacing}
      data-variant={variant}
      {...props}
    >
      {children}
    </div>
  );
};

type CardHeaderProps = {
  children: ReactNode;
  className?: string;
};

const CardHeader = ({ children, className }: CardHeaderProps) => (
  <div className={clsx(styles.card__header, className)}>{children}</div>
);

type CardContentProps = {
  children: ReactNode;
  className?: string;
};

const CardContent = ({ children, className }: CardContentProps) => (
  <div className={clsx(styles.card__content, className)}>{children}</div>
);

type CardFooterProps = {
  children: ReactNode;
  className?: string;
};

const CardFooter = ({ children, className }: CardFooterProps) => (
  <div className={clsx(styles.card__footer, className)}>{children}</div>
);

export const Card = Object.assign(CardRoot, {
  Header: CardHeader,
  Content: CardContent,
  Footer: CardFooter,
});
