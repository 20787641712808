import * as RadixSwitch from '@radix-ui/react-switch';
import clsx from 'clsx';
import React, { ReactElement, ReactNode, useId } from 'react';
import styles from './Switch.module.scss';

export interface ISwitch {
  /** The state of the switch when it is initially rendered. Use when you do not need to control its state. */
  defaultChecked?: boolean;
  /** The controlled state of the switch. Must be used in conjunction with `onChange`. */
  checked?: boolean;
  onChange?: (checked: boolean) => void;

  /** The name of the switch. Submitted with its owning form as part of a name/value pair. */
  name?: string;
  /** The value given as data when submitted with a name. */
  value?: string;

  children?: ReactNode | ReactNode[];
  label?: string;
  // TODO align with Figma
  // title?: string;
  // description?: string;

  size?: 'small' | 'large';
  className?: string;

  autoFocus?: boolean;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLButtonElement>) => void;
}

/**
 * Switch component
 *
 * @version base
 * @status wip
 * @todo support `title` and `description` (instead of children)
 */
export const Switch = ({
  children,
  label,
  size = 'large',
  className,
  defaultChecked = false,
  checked,
  onChange,
  ...props
}: ISwitch): ReactElement => {
  const id = `switch--${useId()}`;

  return (
    <div className={clsx(styles.switch, className)} data-size={size}>
      <RadixSwitch.Root
        className={styles.switchRoot}
        id={id}
        aria-labelledby={`${id}-label`}
        defaultChecked={defaultChecked}
        checked={checked}
        onCheckedChange={(checked) => onChange?.(checked)}
        {...props}
      >
        <RadixSwitch.Thumb className={styles.switchThumb} />
      </RadixSwitch.Root>
      <label className={styles.label} htmlFor={id} id={`${id}-label`}>
        {label && <span>{label}</span>}
        {children && <span className={styles.description}>{children}</span>}
      </label>
    </div>
  );
};

export default Switch;
