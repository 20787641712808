import { captureEvent } from '@sentry/react';
import { redirect } from '../redirect';

export const onInsuranceError = (error?: Error) => {
  console.error(error?.message);
  captureEvent({
    message: 'Insurance OnError',
    extra: {
      error,
    },
  });
  return redirect();
};
