import { SHOW_DEBUG_ERRORS } from '@axo/insurance/const/no';
import { Languages, getTranslation } from '@axo/shared/util/translation';
import { translations } from './translations';

export function useTranslation() {
  return getTranslation<typeof translations, Languages.nb>({
    translations,
    preferredLanguage: Languages.nb,
    showDebugErrors: SHOW_DEBUG_ERRORS,
  });
}
