import {
  IStepperStep,
  IStepperStepState,
} from '@axo/deprecated/util/ui-components';
import { StepperAction } from './actions';
import { Steps } from './stepStates';
import { insurance, insurance_payment } from '@axo/shared/data-access/types';

export interface IStepState<T extends string> extends IStepperStep {
  name: T;
  isShownInStepper: boolean;
  state: IStepperStepState;
  isHeadlineShown: boolean;
}

export type ISteps<T extends IStepperStatePropType> = {
  [key in Extract<keyof T, string>]: IStepState<key> &
    (T[key] extends object ? T[key] : unknown);
};

export type IStepperStatePropType = Partial<Steps>;

export type StepperData = {
  error: string | undefined;
  selectedStep: keyof Steps;
  insurance?: insurance.Insurance;
  insurancePolicy?: insurance.InsurancePolicy;
  insurancePayment?: insurance_payment.Payment;
};

export type StepperState = {
  steps: Steps;
} & StepperData;

export type StepperReducer = (
  state: StepperState,
  action: StepperAction
) => StepperState;

export function stepperReducer(
  state: StepperState,
  action: StepperAction
): StepperState {
  switch (action.type) {
    case 'Set error':
      return { ...state, error: action.payload };
    case 'Set data':
      return { ...state, ...action.payload };
    case 'Set step': {
      return {
        ...state,
        selectedStep: action.payload,
      };
    }
    case 'Set step data': {
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.scope.parentType]: {
            ...state.steps[action.scope.parentType],
            ...action.payload,
          },
        },
      };
    }
    case 'Reset step states': {
      return {
        ...state,
        error: undefined,
        steps: {
          ...state.steps,
          explainer: {
            ...state.steps.explainer,
            state: 'untouched',
          },
          selectCoverage: {
            ...state.steps.selectCoverage,
            state: 'untouched',
          },
          terms: {
            ...state.steps.terms,
            state: 'untouched',
          },
          verifyPaymentCard: {
            ...state.steps.verifyPaymentCard,
            isCompleted: false,
          },
        },
      };
    }
    default:
      return state;
  }
}
