import clsx from 'clsx';
import SpinnerIcon from '../../@assets/graphics/spinner.svg?react';
import styles from './spinner.module.scss';

export const SpinnerSizes = ['xs', 's', 'm', 'l', 'xl'] as const;

type SpinnerProps = {
  size?: (typeof SpinnerSizes)[number];
  className?: string;
};

export const Spinner = ({ size = 'm', className }: SpinnerProps) => {
  return (
    <div className={clsx(styles.spinner, className)} data-size={size}>
      <SpinnerIcon />
    </div>
  );
};
