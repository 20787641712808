import { StepProviderNorway } from '@axo/insurance/feature/providers/no';
import { ErrorBoundary } from '@axo/shared/feature/error-boundary';
import { AnalyticsProvider } from '@axo/shared/services/analytics';
import { getMixpanelConfig } from '@axo/shared/services/analytics/mixpanel/config/getMixpanelConfig';
import { getLDConfigWithAnonymousUser } from '@axo/shared/services/feature-flags';
import * as Sentry from '@sentry/react';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorView } from '../components/ErrorView';
import './base.css';
import { onInsuranceEnd, onInsuranceError } from './config';
import { Main } from './pages/Main/Main';

const LD_CLIENT_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT;
const LD_CONFIG = getLDConfigWithAnonymousUser({
  clientSideID: LD_CLIENT_ID,
  context: {
    site: {
      kind: 'insurance',
      key: 'ins-no',
      country: 'no',
    },
  },
});

!import.meta.env.CI &&
  mixpanel.init(
    import.meta.env.VITE_MIXPANEL_TOKEN as string,
    getMixpanelConfig()
  );

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const ldClient = useLDClient();

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ErrorBoundary
          preventMultipleOnErrorCalls={true}
          fallback={
            <ErrorView
              title="Something went wrong"
              description={
                'Something has gone wrong and My Page has crashed. Please reload and contact your manager if the problem persists.'
              }
            />
          }
        >
          <AnalyticsProvider
            enabledServices={[
              'mixpanel',
              { service: 'launchDarkly', params: { client: ldClient } },
            ]}
          >
            <Routes>
              <Route
                path="/"
                element={
                  <Main onEnd={onInsuranceEnd} onError={onInsuranceError} />
                }
              >
                <Route
                  index
                  element={
                    <StepProviderNorway
                      onEnd={onInsuranceEnd}
                      onError={onInsuranceError}
                      sourceTag="application-form-flow"
                    />
                  }
                />
              </Route>
            </Routes>
          </AnalyticsProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default withLDProvider(LD_CONFIG)(App);
