import {
  ErrorModel,
  ErrorText,
  Span,
} from '@axo/deprecated/util/ui-components';
import { Switch } from '@axo/shared/ui/input';
import { classNames } from '@axo/shared/util/dom';
import { ReactNode } from 'react';
import sanitizeHtml from 'sanitize-html';
import styles from './SwitchWithLabels.module.scss';

export const SwitchWithLabels = ({
  children = '',
  onChange,
  errors = [],
  value = false,
  label,
}: {
  children?: ReactNode;
  onChange?: (newValue: boolean) => void;
  errors?: ErrorModel[];
  value?: boolean;
  label?: string;
}) => {
  return (
    <label className={classNames(styles.root, styles.checkbox)}>
      <div className={styles.row}>
        <Switch checked={value} className={styles.switch} onChange={onChange} />
        <div className={styles.column}>
          {children && <Span>{children}</Span>}
          {label && (
            <Span dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }} />
          )}
          {errors.length > 0 && <ErrorText errors={errors} />}
        </div>
      </div>
    </label>
  );
};
