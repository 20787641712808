import { IAnalyticsService } from './AnalyticsService.types';
import { GoogleTagService } from './google-tag/GoogleTagService';
import { LaunchDarklyService } from './launch-darkly/LaunchDarklyService';
import { MixpanelService } from './mixpanel/MixpanelService';

export type AnalyticsServices = 'gtag' | 'launchDarkly' | 'mixpanel';

export const AnalyticsServicesRegistry: Record<
  AnalyticsServices,
  IAnalyticsService
> = {
  gtag: GoogleTagService,
  launchDarkly: LaunchDarklyService,
  mixpanel: MixpanelService,
};
