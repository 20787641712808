import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { ReactNode, useContext, useEffect } from 'react';
import {
  InsuranceFeatureFlags,
  InsuranceFeatureFlagsValues,
} from '../FeatureFlagSet';
import { StepTitle } from '../StepTitle';
import { StepContext } from './store/context';
import { IStepName } from './store/stepStates';

export function CurrentStep({
  component,
  name,
}: {
  component: ReactNode;
  name: IStepName;
}) {
  const { dispatch, state, isEmbedded } = useContext(StepContext);
  let { flag_ins_step_header_position } = useFlags(
    InsuranceFeatureFlags
  ) as InsuranceFeatureFlagsValues;
  useFlagImpression(InsuranceFeatureFlags, { flag_ins_step_header_position });
  flag_ins_step_header_position ??= 'top';

  const stepData = state.steps[name];

  useEffect(() => {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { state: 'active' },
    });
  }, [stepData.name, dispatch]);

  return (
    <>
      {flag_ins_step_header_position === 'top' && stepData.isHeadlineShown && (
        <StepTitle title={stepData.title} desktopOnly={!isEmbedded} />
      )}
      {component}
    </>
  );
}
