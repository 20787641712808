type Args<T> =
  | {
      baseURL: string;
      path: string;
      token?: string;
      method: 'GET';
      body?: never;
      headers?: Record<string, string>;
    }
  | {
      baseURL: string;
      path: string;
      token?: string;
      method: 'POST' | 'PATCH' | 'PUT' | 'DELETE';
      body?: T;
      headers?: Record<string, string>;
    };
export const send = async <Body, Return = Body>({
  baseURL,
  path,
  token,
  method,
  body,
  headers,
}: Args<Body>): Promise<Return> => {
  const url = new URL(path, baseURL);
  headers = {
    'Content-Type': 'application/json',
    ...(headers ? headers : ''),
  };
  if (token) headers['Authorization'] = token;
  try {
    const response = await fetch(url, {
      method,
      mode: 'cors',
      headers: headers,
      body: body !== undefined ? JSON.stringify(body) : undefined,
    });
    if (!response.ok) {
      throw new Error(`Error: ${response.status}:${response.statusText}`);
    }
    const contentType = response.headers.get('Content-Type');
    if (contentType?.includes('application/json')) {
      return await response.json();
    }
    if (contentType?.includes('text/')) {
      return (await response.text()) as Return;
    }
    return undefined as Return;
  } catch (error) {
    if (error instanceof Error) {
      throw new Error(`Failed to ${method} ${path}: ${error?.message}`);
    }
    throw new Error(`Failed to ${method} ${path}`);
  }
};
