import React from 'react';
// outline (default) icons + solid icons
import AlertCircle from '../../@assets/icons/alert-circle.svg?react';
import AlertTriangle from '../../@assets/icons/alert-triangle.svg?react';
import ArrowClockwiseSolid from '../../@assets/icons/arrow-clockwise-solid.svg?react';
import ArrowDownCircle from '../../@assets/icons/arrow-down-circle.svg?react';
import ArrowDown from '../../@assets/icons/arrow-down.svg?react';
import ArrowLeftCircle from '../../@assets/icons/arrow-left-circle.svg?react';
import ArrowLeft from '../../@assets/icons/arrow-left.svg?react';
import ArrowRightCircle from '../../@assets/icons/arrow-right-circle.svg?react';
import ArrowRight from '../../@assets/icons/arrow-right.svg?react';
import ArrowUpCircle from '../../@assets/icons/arrow-up-circle.svg?react';
import ArrowUp from '../../@assets/icons/arrow-up.svg?react';
import BadgeCheckSolid from '../../@assets/icons/badge-check-solid.svg?react';
import Briefcase from '../../@assets/icons/briefcase.svg?react';
import ChatCircleTextSolid from '../../@assets/icons/chat-circle-text-solid.svg?react';
import CheckCircleSolid from '../../@assets/icons/check-circle-solid.svg?react';
import CheckCircle from '../../@assets/icons/check-circle.svg?react';
import CheckDouble from '../../@assets/icons/check-double.svg?react';
import CheckFatSolid from '../../@assets/icons/check-fat-solid.svg?react';
import Check from '../../@assets/icons/check.svg?react';
import ChevronDown from '../../@assets/icons/chevron-down.svg?react';
import ChevronLeft from '../../@assets/icons/chevron-left.svg?react';
import ChevronRight from '../../@assets/icons/chevron-right.svg?react';
import ChevronUp from '../../@assets/icons/chevron-up.svg?react';
import ClockSolid from '../../@assets/icons/clock-solid.svg?react';
import CloverSolid from '../../@assets/icons/clover-solid.svg?react';
import CreditCardSolid from '../../@assets/icons/credit-card-solid.svg?react';
import Edit from '../../@assets/icons/edit.svg?react';
import ExclamationCircleSolid from '../../@assets/icons/exclamation-circle-solid.svg?react';
import ExternalLink from '../../@assets/icons/external-link.svg?react';
import GlobeSolid from '../../@assets/icons/globe-solid.svg?react';
import HandCoinSolid from '../../@assets/icons/hand-coin-solid.svg?react';
import HandWavingSolid from '../../@assets/icons/hand-waving-solid.svg?react';
import HelpCircle from '../../@assets/icons/help-circle.svg?react';
import InfoSolid from '../../@assets/icons/info-solid.svg?react';
import Info from '../../@assets/icons/info.svg?react';
import LifeBuoySolid from '../../@assets/icons/life-buoy-solid.svg?react';
import Mail from '../../@assets/icons/mail.svg?react';
import MapPin from '../../@assets/icons/map-pin.svg?react';
import MenuLeftSolid from '../../@assets/icons/menu-left-solid.svg?react';
import MenuRightSolid from '../../@assets/icons/menu-right-solid.svg?react';
import MenuSolid from '../../@assets/icons/menu-solid.svg?react';
import Menu from '../../@assets/icons/menu.svg?react';
import MinusCircle from '../../@assets/icons/minus-circle.svg?react';
import MoneySolid from '../../@assets/icons/money-solid.svg?react';
import PencilRulerSolid from '../../@assets/icons/pencil-ruler-solid.svg?react';
import PencilSimpleLine from '../../@assets/icons/pencil-simple-line.svg?react';
import PhoneRingSolid from '../../@assets/icons/phone-ring-solid.svg?react';
import Phone from '../../@assets/icons/phone.svg?react';
import PlusCircle from '../../@assets/icons/plus-circle.svg?react';
import PlusSquareSolid from '../../@assets/icons/plus-square-solid.svg?react';
import PuzzleSolid from '../../@assets/icons/puzzle-solid.svg?react';
import ReceiptRefund from '../../@assets/icons/receipt-refund.svg?react';
import ShieldSolid from '../../@assets/icons/shield-solid.svg?react';
import TableSolid from '../../@assets/icons/table-solid.svg?react';
import Trash from '../../@assets/icons/trash.svg?react';
import UserSolid from '../../@assets/icons/user-solid.svg?react';
import User from '../../@assets/icons/user.svg?react';
import WarningSolid from '../../@assets/icons/warning-solid.svg?react';
import XCircleSolid from '../../@assets/icons/x-circle-solid.svg?react';
import XCircle from '../../@assets/icons/x-circle.svg?react';
import XSolid from '../../@assets/icons/x-solid.svg?react';
import X from '../../@assets/icons/x.svg?react';

export const Icons = [
  'alert-circle',
  'alert-triangle',
  'arrow-clockwise-solid',
  'arrow-down',
  'arrow-down-circle',
  'arrow-left',
  'arrow-left-circle',
  'arrow-right',
  'arrow-right-circle',
  'arrow-up',
  'arrow-up-circle',
  'badge-check-solid',
  'briefcase',
  'chat-circle-text-solid',
  'check',
  'check-fat-solid',
  'check-double',
  'check-circle',
  'check-circle-solid',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'clock-solid',
  'clover-solid',
  'credit-card-solid',
  'edit',
  'exclamation-circle-solid',
  'external-link',
  'globe-solid',
  'help-circle',
  'hand-coin-solid',
  'hand-waving-solid',
  'info',
  'info-solid',
  'life-buoy-solid',
  'mail',
  'map-pin',
  'menu',
  'menu-left-solid',
  'menu-right-solid',
  'menu-solid',
  'minus-circle',
  'money-solid',
  'pencil-ruler-solid',
  'pencil-simple-line',
  'phone',
  'phone-ring-solid',
  'plus-circle',
  'plus-square-solid',
  'puzzle-solid',
  'receipt-refund',
  'shield-solid',
  'table-solid',
  'trash',
  'user',
  'user-solid',
  'warning-solid',
  'x',
  'x-circle',
  'x-circle-solid',
  'x-solid',
] as const;

export type IconName = (typeof Icons)[number];

export const IconComponents: Record<
  IconName,
  React.FC<React.SVGAttributes<SVGElement>>
> = {
  'alert-circle': AlertCircle,
  'alert-triangle': AlertTriangle,
  'arrow-clockwise-solid': ArrowClockwiseSolid,
  'arrow-down': ArrowDown,
  'arrow-down-circle': ArrowDownCircle,
  'arrow-left': ArrowLeft,
  'arrow-left-circle': ArrowLeftCircle,
  'arrow-right': ArrowRight,
  'arrow-right-circle': ArrowRightCircle,
  'arrow-up': ArrowUp,
  'arrow-up-circle': ArrowUpCircle,
  'badge-check-solid': BadgeCheckSolid,
  briefcase: Briefcase,
  'chat-circle-text-solid': ChatCircleTextSolid,
  check: Check,
  'check-fat-solid': CheckFatSolid,
  'check-double': CheckDouble,
  'check-circle': CheckCircle,
  'check-circle-solid': CheckCircleSolid,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'clock-solid': ClockSolid,
  'clover-solid': CloverSolid,
  'credit-card-solid': CreditCardSolid,
  edit: Edit,
  'exclamation-circle-solid': ExclamationCircleSolid,
  'external-link': ExternalLink,
  'globe-solid': GlobeSolid,
  'help-circle': HelpCircle,
  'hand-coin-solid': HandCoinSolid,
  'hand-waving-solid': HandWavingSolid,
  info: Info,
  'info-solid': InfoSolid,
  'life-buoy-solid': LifeBuoySolid,
  mail: Mail,
  'map-pin': MapPin,
  menu: Menu,
  'menu-left-solid': MenuLeftSolid,
  'menu-right-solid': MenuRightSolid,
  'menu-solid': MenuSolid,
  'minus-circle': MinusCircle,
  'money-solid': MoneySolid,
  'pencil-ruler-solid': PencilRulerSolid,
  'pencil-simple-line': PencilSimpleLine,
  phone: Phone,
  'phone-ring-solid': PhoneRingSolid,
  'plus-circle': PlusCircle,
  'plus-square-solid': PlusSquareSolid,
  'puzzle-solid': PuzzleSolid,
  'receipt-refund': ReceiptRefund,
  'shield-solid': ShieldSolid,
  'table-solid': TableSolid,
  user: User,
  'user-solid': UserSolid,
  trash: Trash,
  'warning-solid': WarningSolid,
  x: X,
  'x-circle': XCircle,
  'x-circle-solid': XCircleSolid,
  'x-solid': XSolid,
};
