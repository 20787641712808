export interface IPersonKeyParams {
  personID?: string | null;
}

export const personKeys = {
  root: (params: IPersonKeyParams) => ['person', params.personID] as const,
  insurance: (params: IPersonKeyParams) =>
    [...personKeys.root(params), 'insurance'] as const,
  loanApplications: (params: IPersonKeyParams) =>
    [...personKeys.root(params), 'loan applications'] as const,
};

export function parsePersonKey<T extends keyof typeof personKeys>(
  queryKey: ReturnType<(typeof personKeys)[T]>
) {
  const [, personID] = queryKey;
  return { personID };
}
