/* eslint-disable axo-frontend/directory-structure */
import { A, ContentBox, Hr, Icons } from '@axo/deprecated/util/ui-components';
import {
  IPayOutAndPremium,
  prePurchaseInformationUrl,
  productFactSheetUrl,
} from '@axo/insurance/const/no';
import {
  IAddress,
  InsuranceContext,
  InsuranceFeatureFlags,
  StepContext,
  useCreateInsuranceEvent,
  usePayoutAndPremium,
  type InsuranceFeatureFlagsValues,
} from '@axo/insurance/feature/providers';
import {
  ActionBar,
  Drawer,
  NavWrapper,
  NavigationButton,
  StepNavigator,
} from '@axo/insurance/ui';
import {
  EventCode,
  useEventField,
  useEventLogger,
} from '@axo/shared/data-access/event-log';
import {
  useInsurancePolicy,
  usePatchPerson,
  usePerson,
} from '@axo/shared/data-access/hooks';
import { useAnalytics } from '@axo/shared/services/analytics';
import {
  useFlagImpression,
  useFlags,
} from '@axo/shared/services/feature-flags';
import { debounceFunction } from '@axo/shared/util/fp';

import { MyPageFlags } from '@axo/mypage/util';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import clsx from 'clsx';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import stepStyles from '../../StepProvider.module.scss';
import { currencyFormatter } from '../../currency';
import { useTranslation } from '../../translations/useTranslation';
import AddressForm from './AddressForm';
import { ExplainerItemsA, ExplainerItemsB } from './ExplainerItems';
import styles from './SelectCoverage.module.scss';
import { SelectCoverageDropdown } from './SelectCoverageDropdown';
import { SelectCoverageSlider } from './SelectCoverageSlider';

export const SelectCoverage = () => {
  const { dispatch, state, isEmbedded, onEnd } = useContext(StepContext);
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { t } = useTranslation();
  const patchPerson = usePatchPerson();
  const [continueAttempts, setContinueAttempts] = useState(0);
  const form = useRef<HTMLParagraphElement>(null);
  const { track } = useAnalytics();
  const [isAddressInputValid, setIsAddressInputValid] = useState(true);
  const addEvent = useCreateInsuranceEvent();
  const {
    steps: { selectCoverage: stepData },
  } = state;
  const { state: insuranceState } = useContext(InsuranceContext);
  const insurancePolicy = useInsurancePolicy(
    insuranceState.insurancePolicyReference?.PolicyID ?? undefined
  );
  const { getPayoutAndPremiumItem } = usePayoutAndPremium('no');
  const {
    'show-new-insurance-modal': flag_show_new_insurance_modal,
    'new-insurance-flow-v2': flag_new_insurance_flow_v2,
  } = useFlags(MyPageFlags);

  let {
    flag_ins_step_header_position,
    flag_ins_coverage_selector_style,
    flag_ins_coverage_show_benefits,
    flag_ins_coverage_cta_position,
    flag_ins_coverage_default_amount_no,
    flag_ins_explainer_content,
  } = useFlags(InsuranceFeatureFlags) as InsuranceFeatureFlagsValues;
  useFlagImpression(InsuranceFeatureFlags, {
    flag_ins_step_header_position,
    flag_ins_coverage_selector_style,
    flag_ins_coverage_show_benefits,
    flag_ins_coverage_cta_position,
    flag_ins_coverage_default_amount_no,
    flag_ins_explainer_content,
  });
  flag_ins_step_header_position ??= 'top';
  flag_ins_coverage_selector_style ??= 'slider';
  flag_ins_coverage_show_benefits ??= true;
  flag_ins_coverage_cta_position ??= 'horizontal';
  flag_ins_coverage_default_amount_no ??= 5000;
  flag_ins_explainer_content ??= 'B';

  useEffect(() => {
    if (stepData.insurance.payout !== 0) return;

    dispatch({
      type: 'Set step data',
      scope: { parentType: 'selectCoverage' },
      payload: {
        ...stepData,
        insurance: getPayoutAndPremiumItem(flag_ins_coverage_default_amount_no),
      },
    });
  }, [
    dispatch,
    flag_ins_coverage_default_amount_no,
    getPayoutAndPremiumItem,
    stepData,
  ]);

  const person = usePerson(insurancePolicy.data?.PersonID);
  const hasAddress = person.data?.HasCompleteAddress ?? false;

  const log = useEventLogger();
  useEventField('current-step', 'select-coverage');
  useEventField('payout', stepData.insurance.payout.toString());
  useEventField('premium', stepData.insurance.premium.toString());
  useEventField('has-address', hasAddress.toString());

  useEffect(() => {
    if (!person.isError) return;
    dispatch({
      type: 'Set error',
      scope: { parentType: 'error' },
      payload: 'Something went wrong',
    });
  }, [dispatch, person.isError]);

  const submitAddress = () => {
    if (person.data === undefined || person.data.ID === undefined) {
      return;
    }
    patchPerson.mutate({
      id: person.data.ID,
      data: {
        ...person.data,
        City: address.City,
        Street: address.Street,
        ZipCode: address.ZipCode,
      },
    });
  };

  useEffect(() => {
    if (!person.isSuccess) return;
    if (hasAddress) return;
    if (state.steps.selectCoverage.isFormShown.address) return;
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: {
        isFormShown: { address: true, name: stepData.isFormShown.name },
      },
    });
  }, [
    dispatch,
    stepData,
    hasAddress,
    person.isSuccess,
    state.steps.selectCoverage.isFormShown.address,
  ]);

  const enableNextButton =
    !person.isLoading &&
    (!state.steps.selectCoverage.isFormShown.address || isAddressInputValid);

  const [address, setAddress] = useState<IAddress>(stepData.personData.address);

  const getIsAddressInputValid = useCallback(() => {
    return Object.values(address).every(
      (currentValue) => currentValue.length > 0
    );
  }, [address]);

  const validateAddressInput = useCallback(() => {
    setIsAddressInputValid(getIsAddressInputValid());
  }, [getIsAddressInputValid]);

  useEffect(() => {
    validateAddressInput();
  }, [validateAddressInput]);

  const handleAddressInputChange = (key: keyof IAddress, value: string) => {
    setAddress((values) => {
      values[key] = value;
      return values;
    });
    validateAddressInput();
  };

  async function handleAcceptInsurance() {
    setContinueAttempts((count) => count + 1);
    if (
      state.steps.selectCoverage.isFormShown.address &&
      !isAddressInputValid
    ) {
      form.current?.scrollIntoView({ behavior: 'smooth' });
    }
    if (!enableNextButton) return;
    if (!hasAddress) submitAddress();
    log(EventCode.InsuranceNext);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: {
        state: 'completed',
        personData: { address: address, name: stepData.personData.name },
      },
    });
    track({
      event: `Insurance Step ${stepData.name} Completed`,
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'terms',
    });
  }

  async function handleDeclineInsurance() {
    log(EventCode.InsuranceDeclined);
    addEvent('no/form/insurance-declined', 'select-coverage');
    await track({
      event: 'Insurance Declined',
      options: { send_immediately: true },
      params: { step: 'select-coverage' },
    });
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  function handleGoBack() {
    log(EventCode.InsuranceBack);
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'touched' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'explainer',
    });
  }

  const logSelectCoverage = useMemo(
    () =>
      debounceFunction((newPayout: number) => {
        log(EventCode.SelectCoverage);
        track({
          event: 'Insurance Coverage Amount Changed',
          params: {
            payout: newPayout,
          },
        });
      }),
    [log, track]
  );

  const onAmountSelect = useMemo(
    () => (insurance: IPayOutAndPremium | null) => {
      if (!insurance) {
        return;
      }
      dispatch({
        type: 'Set step data',
        scope: { parentType: stepData.name },
        payload: {
          ...stepData,
          insurance,
        },
      });
      logSelectCoverage(insurance.payout);
    },
    [dispatch, logSelectCoverage, stepData]
  );
  return (
    <>
      <ContentBox
        classes={{
          root: clsx(
            stepStyles.contentContainer,
            flag_show_new_insurance_modal && styles.contentContainer
          ),
        }}
      >
        {flag_ins_step_header_position === 'inside' && (
          <div className={styles.shield}>
            <Icons.Shield size="lg" />
            <div>
              <h4 className={styles.productHeader}>
                {t('Axo Laina Insurance')}
              </h4>
              {flag_ins_coverage_selector_style === 'dropdown' && (
                <p
                  className={styles.productHeader}
                  style={{ marginBottom: '0px' }}
                >
                  <span
                    style={{
                      color: 'var(--primary-500)',
                    }}
                  >
                    {currencyFormatter(stepData.insurance.premium)}
                  </span>{' '}
                  /{t('Month')}
                </p>
              )}
            </div>
          </div>
        )}
        {flag_ins_coverage_selector_style === 'dropdown' && (
          <SelectCoverageDropdown
            payout={stepData.insurance}
            onChange={(value: IPayOutAndPremium | null) =>
              onAmountSelect(value)
            }
          />
        )}
        {flag_ins_coverage_selector_style === 'slider' && (
          <SelectCoverageSlider
            onChange={(value: number) =>
              onAmountSelect(
                getPayoutAndPremiumItem(value) as IPayOutAndPremium
              )
            }
            value={stepData.insurance}
          />
        )}
        {!flag_new_insurance_flow_v2 && flag_show_new_insurance_modal && (
          // Explainer
          <div className={styles.explainer}>
            {flag_ins_explainer_content === 'A' ? (
              <ExplainerItemsA />
            ) : (
              <ExplainerItemsB />
            )}
          </div>
        )}
        <div>
          {!flag_show_new_insurance_modal && (
            <Hr className={stepStyles.horizontalSeparator} />
          )}
          <Drawer
            label={t(
              'If you are not sure of the amount of insurance, click here'
            )}
            details={
              <div className={styles.drawerDetails}>
                {t('Add together the monthly cost of your economy')}
              </div>
            }
          />
          <Hr className={stepStyles.horizontalSeparator} />
          {flag_ins_coverage_show_benefits && (
            <>
              <Drawer
                label={t('The benefits of insurance')}
                details={
                  <div className={styles.drawerDetails}>
                    <p>
                      {t(
                        'You decide for yourself where you are using, for example, for rent, loan costs, heating or food'
                      )}
                    </p>
                    <p>{t('Your insurance will follow you, not your loan')}</p>
                  </div>
                }
              />
              {!flag_show_new_insurance_modal && (
                <Hr className={stepStyles.horizontalSeparator} />
              )}
            </>
          )}
        </div>
        <div className={styles.extraInfo}>
          <p className={styles.label}>
            {t('Detailed information on insurance')}
          </p>
          <div className={styles.extraInfoLinkContainer}>
            <div className={styles.extraInfoLink}>
              <Icons.Pdf size="sm" />
              <A href={productFactSheetUrl} target="_blank" rel="noreferrer">
                {t('Product Fact Sheet')}
              </A>
            </div>
            <div className={styles.extraInfoLink}>
              <Icons.Pdf size="sm" />
              <A
                href={prePurchaseInformationUrl}
                target="_blank"
                rel="noreferrer"
              >
                {t('Pre-purchase Information')}
              </A>
            </div>
          </div>
        </div>
        {state.steps.selectCoverage.isFormShown.address && (
          <AddressForm
            ref={form}
            continueAttempts={continueAttempts}
            address={stepData.personData.address}
            onChange={handleAddressInputChange}
          />
        )}
      </ContentBox>
      <NavWrapper
        alwaysStickyNavButtons={isEmbedded}
        className={clsx(flag_show_new_insurance_modal && styles.actions)}
      >
        {flag_show_new_insurance_modal ? (
          <ActionBar
            backButton={{
              // text: t('Back'),
              text: t('Go ahead without insurance'),
              onClick: handleDeclineInsurance,
            }}
            nextButton={{
              text: t('Next'),
              onClick: handleAcceptInsurance,
              disabled: !enableNextButton,
            }}
          />
        ) : (
          <>
            {flag_ins_coverage_cta_position === 'vertical' && (
              <NavigationButton
                width="full-width"
                text={t('Add insurance')}
                variant={enableNextButton ? 'enabled' : 'disabled'}
                onClick={handleAcceptInsurance}
                style={{ marginBottom: 'var(--margin-small-4)' }}
              />
            )}
            <StepNavigator
              leftButton={{
                width: 'standard',
                variant: 'back',
                onClick: () => handleGoBack(),
              }}
              rightButton={
                flag_ins_coverage_cta_position === 'vertical'
                  ? {
                      width: 'standard',
                      text: t('Go ahead without insurance'),
                      variant: 'disabled',
                      onClick: () => handleDeclineInsurance(),
                    }
                  : {
                      width: 'standard',
                      text: t('Yes, continue'),
                      variant: enableNextButton ? 'enabled' : 'disabled',
                      onClick: () => handleAcceptInsurance(),
                    }
              }
            />
          </>
        )}
      </NavWrapper>
    </>
  );
};
