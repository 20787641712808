import { insurance_payment_api } from '@axo/shared/data-access/api';
import { DataAccessContext, useAPI } from '@axo/shared/data-access/provider';
import { useCallback, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  insuranceCustomerKeys,
  parseInsuranceCustomerKey,
} from '../cache-keys/insuranceCustomerKeys';
import { queryFunction } from '../query-function';

export function useInsurancePaymentMandates(
  policyID: string,
  marketCountry: string
) {
  const { state } = useContext(DataAccessContext);
  const api = useAPI();

  const cacheKey = useMemo(
    () =>
      insuranceCustomerKeys.root({
        insuranceID: policyID ?? '',
        customerID: state.user.customerID ?? '',
        marketCountry: marketCountry ?? '',
      }),
    [marketCountry, policyID, state.user.customerID]
  );

  const fetchFunction = useCallback(
    queryFunction(insurance_payment_api.findKidMandates(api), ({ queryKey }) =>
      parseInsuranceCustomerKey(
        queryKey as keyof (typeof insuranceCustomerKeys)['root']
      )
    ),
    [api]
  );

  return useQuery(cacheKey, fetchFunction, {
    enabled: !!(policyID && state.user.customerID && marketCountry),
  });
}
