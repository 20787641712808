import { ReactNode } from 'react';
import { useTranslation } from '../../translations/useTranslation';
import { Icon, IconName } from '@axo/ui-core/components/Icon';

export type ExplainerItemProps = {
  icon: IconName;
  text: string | ReactNode;
};

const ExplainerItem = ({ icon, text }: ExplainerItemProps) => (
  <div>
    <Icon name={icon} size="xs" />
    <span>{text}</span>
  </div>
);

export const ExplainerItemsA = () => {
  const { t } = useTranslation();
  const items: ExplainerItemProps[] = [
    {
      icon: 'check-circle-solid',
      text: t(
        "Even if you don't expect it, illness or losing your job can happen to anyone"
      ),
    },
    {
      icon: 'check-circle-solid',
      text: t(
        'In the event of death, our expense insurance pays a lump sum of NOK 50,000 to your next of kin'
      ),
    },
  ];

  return items.map(({ icon, text }) => (
    <ExplainerItem icon={icon} text={text} />
  ));
};

export const ExplainerItemsB = () => {
  const { t } = useTranslation();
  const items: ExplainerItemProps[] = [
    {
      icon: 'life-buoy-solid',
      text: t('Take care of your payments with peace of mind in an emergency'),
    },
    {
      icon: 'shield-solid',
      text: t(
        'Keep your finances stable when unemployment hits or you get sick'
      ),
    },
    {
      icon: 'money-solid',
      text: t('The compensation is paid directly to your account'),
    },
    {
      icon: 'globe-solid',
      text: t(
        'Ensure insurance is valid 24 hours a day and around the world for the first 12 months'
      ),
    },
    {
      icon: 'arrow-clockwise-solid',
      text: t(
        'To change the amount of insurance claim or terminate your insurance at any time'
      ),
    },
    {
      icon: 'plus-square-solid',
      text: t(
        'Our Maksuturva insurance covers a lump sum in the event of death'
      ),
    },
  ];

  return items.map(({ icon, text }) => (
    <ExplainerItem icon={icon} text={text} />
  ));
};
