import { Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Explainer.module.scss';

export const ExplainerContentA = () => {
  const { t } = useTranslation();

  return (
    <>
      <p>
        {t(
          "Even if you don't expect it, illness or losing your job can happen to anyone"
        )}
      </p>
      <ul>
        <li className={styles.listContent}>
          <Icons.CircleCheckMarkValid size="sm" />
          <div>
            {t(
              'Offers financial protection (up to NOK 10,000 per month) in the event of involuntary unemployment or illness'
            )}
          </div>
        </li>
        <li className={styles.listContent}>
          <Icons.CircleCheckMarkValid size="sm" />
          <div>
            {t(
              'In the event of death, our expense insurance pays a lump sum of NOK 50,000 to your next of kin'
            )}
          </div>
        </li>
      </ul>
    </>
  );
};

export const ExplainerContentB = () => {
  const { t } = useTranslation();
  return (
    <>
      <p>{t('Before you proceed, would you like to add insurance')}</p>
      <ul className={styles.ul}>
        <li className={styles.listContent}>
          <Icons.Lifesaver size="sm" />
          {t('Take care of your payments with peace of mind in an emergency')}
        </li>
        <li className={styles.listContent}>
          <Icons.ShieldOutline size="sm" />
          {t(
            'Keep your finances stable when unemployment hits or you get sick'
          )}
        </li>
        <li className={styles.listContent}>
          <Icons.Money size="sm" />
          {t('The compensation is paid directly to your account')}
        </li>
        <li className={styles.listContent}>
          <Icons.Globe size="sm" />
          {t(
            'Ensure insurance is valid 24 hours a day and around the world for the first 12 months'
          )}
        </li>
        <li className={styles.listContent}>
          <Icons.Reload size="sm" />
          {t(
            'To change the amount of insurance claim or terminate your insurance at any time'
          )}
        </li>
        <li className={styles.listContent}>
          <Icons.Emergency size="sm" />
          {t(
            'Our Maksuturva insurance covers a lump sum in the event of death'
          )}
        </li>
      </ul>
    </>
  );
};
