/* eslint-disable axo-frontend/directory-structure */
import {
  insuranceInitialState,
  InsuranceState,
  StepperState,
  Steps,
} from '@axo/insurance/feature/providers';
import {
  initialState as dataAccessInitialState,
  DataAccessState,
} from '@axo/shared/data-access/provider';
import {
  loanApplicationInitialState,
  LoanApplicationState,
} from '@axo/shared/feature/providers';
import { URLSearchParams } from 'url';
import { initialState as stepInitialState } from '../StepProvider/store/initialState';

function crateStateKey() {
  if (window.location.protocol === 'https' && 'crypto' in window) {
    return window.crypto.randomUUID();
  }

  return (Math.random() + 1).toString(36).substring(2);
}

export function getInitialDataAccessContextState(search: URLSearchParams) {
  const state = search.get('state');

  if (!state) return dataAccessInitialState;

  const item = sessionStorage.getItem(state);

  if (!item) return dataAccessInitialState;

  return JSON.parse(item).dataAccessContext as DataAccessState;
}

export function getInitialStepContextState(
  search: URLSearchParams,
  initialStep?: keyof Steps,
  isEmbedded?: boolean
) {
  const initialState = stepInitialState(isEmbedded);

  if (initialStep) return { ...initialState, selectedStep: initialStep };

  const state = search.get('state');

  if (!state) return initialState;

  const item = sessionStorage.getItem(state);

  if (!item) return initialState;

  return JSON.parse(item).stepContext as StepperState;
}

export function getInitialAppContextState(search: URLSearchParams) {
  const state = search.get('state');

  if (!state) return loanApplicationInitialState;

  const item = sessionStorage.getItem(state);

  if (!item) return loanApplicationInitialState;

  return JSON.parse(item).applicationContext as LoanApplicationState;
}

export function getInitialInsuranceContextState(
  search: URLSearchParams,
  initialData?: Partial<InsuranceState>
) {
  const state = search.get('state');

  if (!state) return { ...insuranceInitialState, ...initialData };

  const item = sessionStorage.getItem(state);

  if (!item) return { ...insuranceInitialState, ...initialData };

  return JSON.parse(item).insuranceContext as InsuranceState;
}

export interface IStoredState {
  dataAccessContext: DataAccessState;
  applicationContext: LoanApplicationState;
  insuranceContext: InsuranceState;
  stepContext: StepperState;
}

export function create() {
  const key = crateStateKey();

  const url = new URL(window.location.href);
  url.searchParams.delete('id');
  url.searchParams.delete('success');
  url.searchParams.delete('error');
  url.searchParams.set('state', key);

  return { url: url.toString(), key };
}

export function get(url: URL) {
  const key = url.searchParams.get('state');

  if (!key) throw new Error(`Missing state param`);

  return { url: url.toString(), key };
}

export function store(key: string, state: IStoredState) {
  sessionStorage.setItem(key, JSON.stringify(state));
}
