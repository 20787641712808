import { useQuery } from 'react-query';
import {
  insurancePaymentKeys,
  parseInsurancePaymentKey,
} from '../cache-keys/insurancePaymentKeys';
import { insurance_payment } from '@axo/shared/data-access/types';
import { insurance_payment_api } from '@axo/shared/data-access/api';
import { useAPI } from '@axo/shared/data-access/provider';
import { queryFunction } from '../query-function';

export function useInsurancePaymentMandate(
  insurancePaymentID: string,
  complete: boolean,
  isEnabled?: boolean
) {
  const api = useAPI();
  const cacheKey = insurancePaymentKeys.root({
    insurancePaymentID,
  });

  return useQuery(
    cacheKey,
    queryFunction(
      insurance_payment_api.getKidMandate(api),
      ({ queryKey }) => parseInsurancePaymentKey(queryKey).insurancePaymentID
    ),
    {
      // refetch every second until we have an EmbedURL
      refetchInterval: (data) =>
        !data?.PaymentURL ||
        (data.Status === insurance_payment.Status.Pending && complete)
          ? 1_000
          : false,
      keepPreviousData: true,
      enabled: isEnabled,
    }
  );
}
