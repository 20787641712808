/* eslint-disable axo-frontend/directory-structure */
import { StepContext } from '@axo/insurance/feature/providers';
import { NavWrapper, StepNavigator } from '@axo/insurance/ui';
import { DataAccessContext } from '@axo/shared/data-access/provider';
import { LoanApplicationContext } from '@axo/shared/feature/providers';
import { ReactNode, useContext } from 'react';
import { useTranslation } from '../../translations/useTranslation';
import styles from './Error.module.scss';

export const Error = () => {
  const { dispatch, state, onEnd, isEmbedded } = useContext(StepContext);
  const { state: dataAccessContext } = useContext(DataAccessContext);
  const { state: applicationContext } = useContext(LoanApplicationContext);
  const { t } = useTranslation();

  const {
    steps: { explainer: stepData },
  } = state;

  if (!['completed', 'active'].includes(stepData.state)) {
    dispatch({
      type: 'Set step data',
      scope: { parentType: stepData.name },
      payload: { ...stepData, state: 'active' },
    });
  }

  function handleNext() {
    dispatch({
      type: 'Reset step states',
      scope: { parentType: 'error' },
    });
    dispatch({
      type: 'Set step',
      scope: { parentType: 'step' },
      payload: 'explainer',
    });
  }

  function handleDeclineInsurance() {
    const token = dataAccessContext.user.token;
    const uid = applicationContext?.application?.ID;
    onEnd.action({ token, uid });
  }

  const getHeadline = (): ReactNode | string => {
    switch (state.error) {
      case 'Payment failed':
        return t('Payment failed - headline');
      case 'Session expired':
        return t('Session expired - headline');
      case 'Something went wrong':
        return t('Something went wrong - headline');
    }
  };

  const getBodyText = (): ReactNode | string => {
    switch (state.error) {
      case 'Payment failed':
        return t('Payment failed - body');
      case 'Session expired':
        return t('Session expired - body');
      case 'Something went wrong':
        return t('Something went wrong - body');
    }
  };

  const getContinueButtonText = () => {
    switch (state.error) {
      case 'Payment failed':
        return t('Yes, continue with insurance');
      case 'Something went wrong':
        return t('Refresh page');
    }
  };

  const getBackButtonText = () => {
    switch (state.error) {
      case 'Payment failed':
        return t('No, move forward without insurance');
      case 'Something went wrong':
        return t('Try later');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.errorContainer}>
        <h3 className={styles.headline}>{getHeadline()}</h3>
        {getBodyText()}
      </div>
      <NavWrapper alwaysStickyNavButtons={isEmbedded}>
        <StepNavigator
          leftButton={{
            variant: 'secondary',
            text: getBackButtonText(),
            onClick: handleDeclineInsurance,
            width: 'standard',
          }}
          rightButton={{
            variant: 'enabled',
            text: getContinueButtonText(),
            onClick: handleNext,
            width: 'standard',
          }}
        />
      </NavWrapper>
    </div>
  );
};
