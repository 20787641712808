import { Hr } from '@axo/deprecated/util/ui-components';
import { TextInput } from '@axo/shared/ui/input';
import { forwardRef } from 'react';
import stepStyles from '../../StepProvider.module.scss';
import { useTranslation } from '../../translations/useTranslation';
import styles from './AddressForm.module.scss';
import { IAddress } from '@axo/insurance/feature/providers';

export default forwardRef(
  (
    {
      address,
      onChange,
      continueAttempts,
    }: {
      continueAttempts: number;
      address: IAddress;
      onChange: (key: keyof IAddress, value: string) => void;
    },
    ref: React.ForwardedRef<HTMLParagraphElement>
  ) => {
    const { t } = useTranslation();

    return (
      <>
        <Hr className={stepStyles.horizontalSeparator} />
        <p ref={ref}>{t('Address info')}</p>
        <TextInput
          label={t('Address street')}
          onChange={(e) => onChange('Street', e.target.value)}
          state={
            continueAttempts && address.Street.length === 0
              ? 'invalid'
              : 'undetermined'
          }
          value={address.Street}
        />
        <div className={styles.inlineInputContainer}>
          <div className={styles.inlineInputItem}>
            <TextInput
              label={t('Address city')}
              onChange={(e) => onChange('City', e.target.value)}
              state={
                continueAttempts && address.City.length === 0
                  ? 'invalid'
                  : 'undetermined'
              }
              value={address.City}
            />
          </div>
          <div className={styles.inlineInputItem}>
            <TextInput
              label={t('Address zip')}
              onChange={(e) => onChange('ZipCode', e.target.value)}
              state={
                continueAttempts && address.ZipCode.length === 0
                  ? 'invalid'
                  : 'undetermined'
              }
              value={address.ZipCode}
            />
          </div>
        </div>
      </>
    );
  }
);
