import { isBrowser } from '@axo/shared/util/dom';
import { CrossDomainAuthData, StorageKeys } from '../CrossDomainAuth.types';

export const retrieveCrossDomainAuth = (): CrossDomainAuthData => {
  if (!isBrowser()) return { applicationId: undefined, token: undefined };

  const applicationId = sessionStorage.getItem(StorageKeys.AppId) || undefined;
  const token = sessionStorage.getItem(StorageKeys.Token) || undefined;
  return { applicationId, token };
};
