/* eslint-disable axo-frontend/directory-structure */
import { A } from '@axo/deprecated/util/ui-components';
import {
  axoSupportPhoneNumber,
  prePurchaseInformationAndTermsAndConditionsUrl,
} from '@axo/insurance/const/no';
import { Params } from '@axo/shared/util/translation';

export const translations = {
  //general
  'Go back': () => {
    return {
      en: 'Go back',
      fi: 'Takaisin',
      nb: 'Tilbake',
    };
  },
  'Something went wrong': () => {
    return {
      en: 'Something went wrong',
      fi: 'Jotain meni pieleen', // waiting for translation
    };
  },
  'Call customer service if the problem persists': () => {
    return {
      en: 'Call customer service if the problem persists.',
      fi: (
        <>
          Ongelman jatkuessa ota yhteyttä asiakaspalveluun{' '}
          <a href="tel:0942452326">09 4245 2326</a>.
        </>
      ),
    };
  },
  Retry: () => {
    return {
      en: 'Retry',
      fi: 'Yritä uudelleen', // waiting for translation
    };
  },
  Insurance: () => {
    return {
      en: 'Insurance',
      fi: 'Insurance', // waiting for translation
    };
  },
  'Axo finance: My Page': () => {
    return {
      en: 'Axo finance: Insurance',
      fi: 'Axo finance: Insurance', // waiting for translation
    };
  },
  Popular: () => {
    return {
      en: 'Popular',
      fi: 'Suositeltu',
      nb: 'Anbefalt',
    };
  },
  Back: () => {
    return {
      en: 'Back',
      fi: 'Takaisin',
      nb: 'Tilbake',
    };
  },
  Next: () => {
    return {
      en: 'Next',
      fi: 'Eteenpäin',
      nb: 'Neste',
    };
  },
  'To proceed, you must accept the terms': () => {
    return {
      en: 'To proceed, you must accept the terms',
      fi: 'Jatkaaksesi sinun täytyy täyttää yllä mainitut ehdot',
      nb: 'For å fortsette må du akseptere dette vilkåret',
    };
  },
  //Explainer
  'Axo Laina Insurance': () => {
    return {
      en: 'Axo Laina Insurance',
      fi: 'Axolaina Maksuturva',
      nb: 'Axo utgiftsforsikring',
    };
  },
  "Even if you don't expect it, illness or losing your job can happen to anyone":
    () => {
      return {
        en: `Even if you don't expect it, illness or losing your job can happen to anyone. Our security insurance helps you to cover important monthly expenses, and can help you through them difficult situations. The insurance consists of 2 parts:`,
        nb: `Selv om man ikke forventer det, kan sykdom eller det å miste jobben skje med hvem som helst. Vår trygghetsforsikring hjelper deg med å dekke viktige månedlige utgifter, og kan hjelpe deg gjennom de vanskelige situasjonene. Forsikringen består av 2 deler:`,
      };
    },
  'Offers financial protection (up to NOK 10,000 per month) in the event of involuntary unemployment or illness':
    () => {
      return {
        en: 'Offers financial protection (up to NOK 10,000 per month) in the event of involuntary unemployment or illness',
        nb: (
          <>
            Tilbyr en økonomisk beskyttelse (opptil{' '}
            <span style={{ color: 'var(--info-700)' }}>
              10 000 kroner i måneden
            </span>
            ) ved ufrivillig arbeidsløshet eller sykdom
          </>
        ),
      };
    },
  'In the event of death, our expense insurance pays a lump sum of NOK 50,000 to your next of kin':
    () => {
      return {
        en: 'In the event of death, our expense insurance pays a lump sum of NOK 50,000 to your next of kin',
        nb: (
          <>
            Ved dødsfall utbetaler vår utgiftsforsikring et engangsbeløp på{' '}
            <span style={{ color: 'var(--info-700)' }}>50 000 kroner</span> til
            dine pårørende
          </>
        ),
      };
    },
  'Before you proceed, would you like to add insurance': () => {
    return {
      en: 'Before you proceed, would you like to add insurance.',
      fi: 'Haluatko lisätä vakuutuksen ennen eteenpäin siirtymistä? Vakuutus auttaa sinua ylläpitämään taloudellista vakautta kattamalla lainasi kuukausimaksut yllättävissä elämäntilanteissa.',
      nb: 'Vil du legge til forsikring før du går videre? En utgiftsforsikring kan være en sikkerhet for deg om det skulle oppstå endringer i din livssituasjon, enten det er knyttet til din arbeidssituasjon eller helse',
    };
  },
  'Take care of your payments with peace of mind in an emergency': () => {
    return {
      en: 'Take care of your payments with peace of mind in an emergency',
      fi: 'Säilytä taloudellinen mielenrauha myös hätätilanteissa',
      nb: 'Månedlig utbetaling opp til kr 10 000 ved bortfall av inntekt',
    };
  },
  'Keep your finances stable when unemployment hits or you get sick': () => {
    return {
      en: 'Keep your finances stable when unemployment hits or you get sick.',
      fi: 'Pidä taloutesi vakaana työttömyyden iskiessä tai sairastuessasi',
      nb: 'Utbetaling hver måned hvis du blir arbeidsledig, sykemeldt eller permittert',
    };
  },
  'The compensation is paid directly to your account': () => {
    return {
      en: 'The compensation is paid directly to your account',
      fi: 'Korvaus maksetaan suoraan tilillesi',
      nb: 'Pengene utbetales direkte til din konto, og du kan bruke dem som du vil',
    };
  },
  'Ensure insurance is valid 24 hours a day and around the world for the first 12 months':
    () => {
      return {
        en: 'Ensure insurance is valid 24 hours a day and around the world for the first 12 months.',
        fi: 'Maksuturva on voimassa ympäri vuorokauden ja ympäri maailmaa ensimmäisen 12 kuukauden ajan',
        nb: 'Utgiftsforsikringen er gyldig døgnet rundt og over hele verden',
      };
    },
  'To change the amount of insurance claim or terminate your insurance at any time':
    () => {
      return {
        en: 'To change the amount of insurance claim or terminate your insurance at any time.',
        fi: 'Voit muuttaa vakuutuskorvauksen määrää tai irtisanoa vakuutuksesi milloin tahansa',
        nb: 'Du kan når som helst si opp forsikringen',
      };
    },
  'Our Maksuturva insurance covers a lump sum in the event of death': () => {
    return {
      en: 'Our Maksuturva insurance covers a lump sum in the event of death',
      fi: 'Maksuturva-vakuutuksemme korvaa kertakorvauksen kuolemantapauksessa',
      nb: 'Ved dødsfall utbetales et engangsbeløp på kr 50 000 til dine pårørende',
    };
  },
  'Maybe later': () => {
    return {
      en: 'Maybe later',
      fi: 'Ehkä myöhemmin',
      nb: 'Nei takk, gå videre uten forsikring',
    };
  },
  'Sign loan agreement': () => ({
    en: 'Sign loan agreement',
    nb: 'Signer låneavtalen',
  }),
  'Continue with insurance': () => ({
    en: 'Continue with insurance',
    nb: 'Forsett med forsikring',
  }),
  'Secure your economy': () => {
    return {
      en: 'Secure your economy',
      fi: 'Turvaa taloutesi',
      nb: 'Takk for din lånesøknad! Ønsker du å legge til Axo utgiftsforsikring?',
    };
  },
  'Insurance explainer headline': () => {
    return {
      en: 'Insurance information',
      nb: 'Forsikringsinformasjon',
    };
  },
  //SelectCoverage
  'Product details': () => ({
    en: 'Product details',
    fi: 'Tuotteen tiedot',
    nb: 'Produktdetaljer',
  }),
  'Axo insurance': () => ({
    en: 'Axo insurance',
    fi: '',
    nb: 'Axo utgiftsforsikring',
  }),
  'Your insurance': () => {
    return {
      en: 'Your insurance',
      fi: 'Vakuutuksesi',
      nb: 'Forsikringen din',
    };
  },
  'Choose your insurance level': () => {
    return {
      en: 'Choose your insurance level',
      fi: 'Vakuutusmäärä',
      nb: 'Forsikringssum',
    };
  },
  'Payable in the event of damage': () => {
    return {
      en: 'Payable in the event of damage',
      fi: 'Maksetaan vahingon sattuessa',
      nb: 'Utbetales ved skade',
    };
  },
  'Monthly payout': () => {
    return {
      en: 'Monthly payout',
      fi: 'Maksetaan vahingon sattuessa',
      nb: 'Månedlig utbetalingsbeløp',
    };
  },
  Month: () => {
    return {
      en: 'Month',
      fi: 'kk',
      nb: 'mnd',
    };
  },
  'Monthly cost': () => {
    return {
      en: 'Monthly cost',
      fi: 'Kuukausimaksu',
      nb: 'Månedlig premie',
    };
  },
  'Monthly insurance cost': () => {
    return {
      en: 'Monthly cost',
      fi: 'Kuukausimaksu',
      nb: 'Månedlig forsikringspremie',
    };
  },
  'Security insurance - monthly cost': () => ({
    en: 'Security insurance - monthly cost',
    fi: 'Turvavakuutus - kuukausittaiset kustannukset',
    nb: 'Sikkerhetsforsikring - månedlig kostnad',
  }),
  'Your selection': () => ({
    en: 'Your selection',
    fi: 'Valintasi',
    nb: 'Ditt valg',
  }),
  'Loan offer': () => ({
    en: 'Loan offer',
    nb: 'Lånetilbud',
    fi: 'Lainatarjous',
  }),

  // Explainer block
  // TODO: Content from design. Delete if we don't need it.
  // 'One time payment of NOK 50, 000 in case of death': () => ({
  //   en: 'One time payment of NOK 50, 000 in case of death',
  //   fi: 'Kertamaksu kuolemantapauksessa',
  //   nb: 'Engangsutbetaling på 50 000 kroner ved dødsfall',
  // }),
  // 'Monthly benefits straight to your account': () => ({
  //   en: 'Monthly benefits straight to your account',
  //   fi: 'Kuukausittaiset edut suoraan tilillesi',
  //   nb: 'Månedlige utbetalinger direkte til kontoen din',
  // }),
  // 'Valid around the clock and worldwide': () => ({
  //   en: 'Valid around the clock and worldwide',
  //   fi: 'Voimassa 24 tuntia vuorokaudessa ja maailmanlaajuisesti',
  //   nb: 'Gjelder døgnet rundt og over hele verden',
  // }),
  // 'Change and cancel at any time': () => ({
  //   en: 'Change and cancel at any time',
  //   fi: 'Muuta ja peruuta milloin tahansa',
  //   nb: 'Endre og avbryte når som helst',
  // }),

  'If you are not sure of the amount of insurance, click here': () => {
    return {
      en: 'If you are not sure of the amount of insurance, click here',
      fi: 'Jos et ole varma vakuutuksen suuruudesta, klikkaa tästä',
      nb: 'Vurdering av krav og behov for forsikringen: klikk her for informasjon',
    };
  },
  'Add together the monthly cost of your economy': () => {
    return {
      en: 'Add together the monthly cost of your economy, (eg housing, food, fuel, loans) and compare them to unemployment benefit or sickness allowance you would receive.',
      fi: 'Laske yhteen taloutesi kuukausittaiset kustannukset, (esim. asuminen, ruoka, polttoaine, lainat) ja vertaa niitä työttömyyskorvaukseen tai sairauspäivärahaan, jota saisit.',
      nb: (
        <>
          <p>
            Hovedelementet i forsikringen er en månedlig erstatning ved
            ufrivillig arbeidsledighet. Forsikringen er derfor primært utformet
            for å møte kravene og behovene til forbrukere som kan ha
            vanskeligheter med å dekke sine utgifter, eller som har behov for
            større økonomisk trygghet ved ufrivillig arbeidsledighet.
          </p>
          <p>
            Vi anbefaler at du går gjennom følgende fire trinn for å vurdere om
            forsikringen vil passe for deg:
          </p>
          <ol>
            <li>
              Lag et budsjett for å få oversikt over hvilke månedlige utgifter
              du vil komme til å ha, dersom du skulle bli ufrivillig
              arbeidsledig.
            </li>
            <li>
              Vurder hvilken annen inntekt du vil kunne ha i denne situasjonen
              (f.eks. dagpenger fra NAV og/eller andre forsikringer, sykepenger,
              oppsparte midler, etc.), og om du vil være komfortabel med nivået
              på din totale inntekt i en slik situasjon.
            </li>
            <li>
              Dersom svaret på spørsmålet i punkt 2 er 'nei', bør du vurdere om
              du ønsker å kjøpe forsikring som gir deg mulighet til å øke din
              totale månedsinntekt i inntil 12 måneder ved ufrivillig
              arbeidsledighet.
            </li>
            <li>
              Dersom du har kommet til at forsikring vil være fordelaktig, vil
              budsjettet kunne hjelpe deg med å velge et passende
              forsikringsbeløp per måned. Husk at erstatningen som utbetales fra
              forsikringen ved ufrivillig arbeidsledighet maksimalt kan utgjøre
              60 prosent av din gjennomsnittlige netto månedslønn i de tre
              månedene rett før oppsigelsen.
            </li>
          </ol>
          <p>
            Forsikringen dekker også dødsfall som følge av sykdom eller ulykke
            og erstatter boet med et engangsbeløp på NOK 50 000 som skal brukes
            til f.eks. begravelsesutgifter.
          </p>
        </>
      ),
    };
  },
  'The benefits of insurance': () => {
    return {
      en: 'The benefits of insurance',
      fi: 'Vakuutuksen edut',
      nb: 'Forsikringsfordeler',
    };
  },
  'You decide for yourself where you are using, for example, for rent, loan costs, heating or food':
    () => {
      return {
        en: 'You decide for yourself where you are using, for example, for rent, loan costs, heating or food.',
        fi: 'Päätät itse, mihin korvauksen käytät, esim. vuokraan, lainakuluihin, lämmitykseen tai ruokaan.',
        nb: 'Du bestemmer selv hva du bruker kompensasjonen til, for eksempel husleie, lånekostnader, oppvarming eller mat.',
      };
    },
  'Your insurance will follow you, not your loan': () => {
    return {
      en: 'Your insurance will follow you, not your loan. This means that your insurance will remain valid even if you repay your loan or change your loan provider.',
      fi: 'Vakuutuksesi seuraa sinua, ei lainaasi. Tämä tarkoittaa, että vakuutuksesi pysyy voimassa, vaikka maksaisit lainasi takaisin tai vaihtaisit lainantarjoajaa.',
      nb: 'Forsikringen din følger deg, ikke lånet ditt. Det betyr at forsikringen din forblir gyldig selv om du betaler tilbake lånet eller bytter låneleverandør.',
    };
  },
  'Detailed information on insurance': () => {
    return {
      en: 'Detailed information on insurance',
      fi: 'Yksityiskohtaiset tiedot vakuutuksesta',
      nb: 'Detaljert informasjon om forsikring',
    };
  },
  'Downloadable information': () => {
    return {
      en: 'Detailed information on insurance',
      fi: 'Yksityiskohtaiset tiedot vakuutuksesta',
      nb: 'Nedlastbar informasjon',
    };
  },
  'Do you want to take insurance': () => {
    return {
      en: 'Do you want to take insurance?',
      fi: 'Lisätäänkö vakuutus?',
      nb: 'Legge til forsikring?',
    };
  },
  'No, move forward without insurance': () => {
    return {
      en: 'No, move forward without insurance',
      fi: 'Ei, siirry eteenpäin ilman vakuutusta',
      nb: 'Nei, gå videre uten forsikring',
    };
  },
  'Yes, continue': () => {
    return {
      en: 'Yes, continue',
      fi: 'Kyllä, jatka',
      nb: 'Ja, fortsett',
    };
  },
  Continue: () => {
    return {
      en: 'Continue',
      fi: 'Kyllä, jatka',
      nb: 'Fortsett',
    };
  },
  'You will start receiving the compensation once the insurance is activated':
    () => {
      return {
        en: 'You will start receiving the compensation once the insurance is activated',
        fi: 'Voit saada korvausta valitsemaltasi korvaustasolta kun vakuutus astuu voimaan ja hyväksymis- ja karenssiaika ovat ohi',
        nb: 'Du kan få erstatning fra det tidspunktet forsikringen trer i kraft og godkjennings- og karenstiden er over',
      };
    },
  'Go ahead without insurance': () => {
    return {
      en: 'Go ahead without insurance',
      nb: 'Gå videre uten forsikring',
    };
  },
  'Add insurance': () => {
    return {
      en: 'Add insurance',
      nb: 'Legg til forsikring',
    };
  },
  'Edit amount': () => {
    return {
      en: 'Edit amount',
      nb: 'Endre beløp',
    };
  },
  'in compensation': () => {
    return {
      en: 'in compensation',
      nb: 'i kompensasjon',
    };
  },
  'The money is paid directly to your account': () => {
    return {
      en: 'The money is paid directly to your account',
      nb: 'Pengene utbetales direkte til din konto',
    };
  },
  'Contributes to cover expenses in the event of illness and unemployment':
    () => {
      return {
        en: 'Contributes to cover expenses in the event of illness and unemployment',
        nb: 'Bidrar til å dekke utgifter ved sykdom og arbeidsløshet',
      };
    },
  'Compensation for up to 12 months': () => {
    return {
      en: 'Compensation for up to 12 months',
      nb: 'Erstatning i opp til 12 måneder',
    };
  },
  'A security insurance can be a security for you should changes occur in your life situation':
    () => {
      return {
        en: 'A security insurance can be a security for you should changes occur in your life situation',
        nb: 'En tryggehtsforsikring kan være en sikkerhet for deg om det skulle oppstå endringer i din livssituasjon. Forsikringen betaler et valgt beløp om du blir ufrivillig arbeidsløs eller rammes av sykdom og ikke kan arbeide. Du bestemmer selv hva du bruker pengene på, for eksempel leie, strømutgifter, mat eller kostnader til å betale lån eller kreditter.',
      };
    },

  //Terms
  Consent: () => {
    return {
      en: 'Consent',
      fi: 'Suostumus',
      nb: 'Samtykke',
    };
  },
  Terms: () => {
    return {
      en: 'Terms',
      fi: 'Edellytykset',
      nb: 'Samtykke',
    };
  },
  '18-65 years old': () => {
    return {
      en: '18-65 years old',
      fi: 'Olen 18-65-vuotias',
      nb: 'Jeg er mellom 18 og 65 år',
    };
  },
  'I live permanently in Finland': () => {
    return {
      en: 'I live permanently in Finland and I have a valid Kela card as evidenced by the Finnish housing-based social security',
      fi: 'Asun vakituisesti Suomessa ja minulla on voimassa oleva Kela-kortti osoituksena Suomen asumisperusteiseen sosiaaliturvaan kuulumisesta',
      nb: 'Jeg bor permanent i Norge og tilhører norsk folketrygd',
    };
  },
  'I am on a permanent employment relationship and work at least 16 hours a week':
    () => {
      return {
        en: 'I am on a permanent employment relationship and work at least 16 hours a week',
        fi: 'Olen vakituisessa työsuhteessa ja työskentelen vähintään 16 tuntia viikossa',
        nb: 'Jeg er fast ansatt og jobber minimum 16 timer i uken.',
      };
    },
  'I am 100 % capable and I am not aware of future lay-off or dismissal':
    () => {
      return {
        en: 'I am 100 % capable and I am not aware of future lay-off or dismissal',
        fi: 'Olen täysin työkykyinen enkä ole tietoinen tulevasta lomautuksesta tai irtisanomisesta',
        nb: 'Jeg er fullt arbeidsfør og kjenner ikke til fremtidige permitteringer',
      };
    },
  'I am not aware of any illness or chronic condition': () => {
    return {
      en: 'I am not aware of illness or chronic condition that can lead to sick leave.In addition, I am not aware of any future sick leave and I understand that the insurance only applies to accidents or illnesses during the insurance period',
      fi: 'En ole tietoinen mistään sairaudesta tai kroonisesta tilasta, joka voi johtaa sairauslomaan. Lisäksi en ole tietoinen mahdollisista tulevista sairauspoissaoloista ja ymmärrän, että vakuutus koskee vain vakuutuskauden aikana sattuvia tapaturmia tai sairauksia',
      nb: 'Jeg kjenner ikke til sykdom eller kroniske lidelser som kan føre til sykefravær. I tillegg er jeg ikke kjent med mulig fremtidig sykefravær og forstår at forsikringen kun gjelder ulykker eller sykdommer som inntreffer i forsikringsperioden. ',
    };
  },
  'I will meet the above conditions': () => {
    return {
      en: 'I will meet the above conditions and confirm that I have read and agree to pre -information and complete insurance terms and conditions.',
      fi: (
        <>
          Täytän yllä mainitut ehdot ja vahvistan, että olen lukenut ja
          hyväksynyt{' '}
          <A
            href={prePurchaseInformationAndTermsAndConditionsUrl}
            target="_blank"
            rel="noreferrer"
          >
            ennakkotiedot ja täydelliset vakuutusehdot
          </A>
        </>
      ),
      nb: (
        <>
          Jeg oppfyller vilkårene nevnt ovenfor og bekrefter at jeg har lest og
          akseptert{' '}
          <A
            href={prePurchaseInformationAndTermsAndConditionsUrl}
            target="_blank"
            rel="noreferrer"
          >
            forhåndsinformasjon og fullstendige forsikringsvilkår.
          </A>
        </>
      ),
    };
  },
  'I understand that security insurance is valid despite': () => {
    return {
      en: 'I understand that security insurance is valid despite if I am granted a loan or not.',
      fi: 'Ymmärrän, että Maksuturva-vakuutus on voimassa huolimatta siitä, myönnetäänkö minulle lainaa tai ei.',
      nb: 'Jeg forstår at utgiftsforsikringen er gyldig uansett om jeg får innvilget lån eller ikke',
    };
  },
  'To proceed, you must be eligible': () => {
    return {
      en: 'To proceed, you must be eligible',
      fi: 'Jatkaaksesi sinun täytyy täyttää edellytykset',
      nb: 'For å fortsette må du bekrefte at du oppfyller vilkårene',
    };
  },

  //PowerOfAttorney
  TermsOfPayment: () => {
    return {
      en: 'Terms',
      fi: 'Ehdot',
      nb: 'Vilkår',
    };
  },
  'power of attorny text': (params: Params) => {
    return {
      en: (
        <>
          <p>
            {`I ${params[0]} ${params[1]}, I agree that Axo Loan may at any time charge my registered debit card to cover insurance premiums without a separate permit before each charge in accordance with the insurance certificate.`}
          </p>
          <p>
            I guarantee that I am the debit card holder I register For Axo Loan.
          </p>
          <p>
            Monthly charged amounts and insurance conditions appear in an
            insurance certificate or other notification and may vary by billing.
          </p>
          <p>
            I am responsible for updating my information to Axo Loan Changes
            when appeared.
          </p>
          <p>
            This proxy is valid from the proxy signature date for it until it is
            canceled in writing.
          </p>
        </>
      ),
      fi: (
        <>
          <p>
            {`Minä ${params[0]} ${params[1]} hyväksyn, että Axolaina voi milloin tahansa veloittaa rekisteröityä maksukorttiani vakuutusmaksujen kattamiseen ilman erillistä lupaa ennen jokaista veloitusta vakuutustodistuksen mukaisesti.`}
          </p>
          <p>
            Takaan, että olen sen maksukortin haltija, jonka rekisteröin
            Axolainalle.
          </p>
          <p>
            Kuukausittain veloitettavat summat ja vakuutuksen ehdot näkyvät
            vakuutustodistuksessa tai muussa ilmoituksessa, ja ne voivat
            vaihdella laskutuskohtaisesti.
          </p>
          <p>
            Olen vastuussa tietojeni päivittämisestä Axolainalle muutoksien
            ilmaantuessa.
          </p>
          <p>
            Tämä valtakirja on voimassa valtakirjan allekirjoituspäivästä siihen
            asti, kunnes se kirjallisesti peruutetaan.
          </p>
        </>
      ),
    };
  },
  Date: () => {
    return {
      en: 'Date',
      fi: 'Päivämäärä',
    };
  },
  'Authorized person (cardholder)': () => {
    return {
      en: 'Authorized person (cardholder)',
      fi: 'Valtakirjan antaja (kortinhaltija)',
    };
  },
  'Power of attorney': () => {
    return {
      en: 'Power of attorney:',
      fi: 'Valtakirjan vastaanottaja',
    };
  },
  'Proxy confirmation': () => {
    return {
      en: 'Proxy confirmation',
      fi: 'Valtakirjan vahvistus',
    };
  },
  'I have read and accepted the above terms and proxy': () => {
    return {
      en: 'I have read and accepted the above terms and proxy',
      fi: 'Olen lukenut ja hyväksynyt edellä mainitut ehdot ja valtakirjan',
    };
  },
  'Confirm and pay': () => {
    return {
      en: 'Confirm and pay',
      fi: 'Vahvista ja maksa',
    };
  },
  'Accept and pay': () => {
    return {
      en: 'Accept and pay',
      fi: 'Hyväksy ja maksa',
      nb: 'Bekreft og betal',
    };
  },
  // VerifyPaymentCard
  Accept: () => {
    return {
      en: 'Accept',
      fi: 'Hyväksy',
    };
  },
  //error
  'Please try again or contact support at 00000000': () => {
    return {
      en: 'Please try again or contact support at 00000000',
      fi: 'Please try again or contact support at 00000000', // waiting for translation
    };
  },
  'A technical error has occured. Please try again or contact support at 00000000':
    () => {
      return {
        en: 'A technical error has occured. Please try again or contact support at 00000000',
        fi: 'A technical error has occured. Please try again or contact support at 00000000', // waiting for translation
      };
    },

  //Summary
  'Thank you': () => {
    return {
      en: 'Thank you',
      fi: 'Kiitos',
      nb: 'Takk',
    };
  },
  'Congratulations with your insurance': () => {
    return {
      en: (
        <>
          <p>
            Foreseeing the future is difficult. Thus protecting your economy is
            a smart choice in case your life situation suddenly changes.
          </p>
          <p>
            An email with details about the insurance will be sent to you within
            24 hours. If you have any further questions, do not hesitate to
            contact us.
          </p>
        </>
      ),
      fi: (
        <>
          <p>
            Onnittelut uudesta Axolaina Maksuturva -vakuutuksestasi. Koska
            tulevaisuuden ennustaminen on vaikeaa, on aina järkevää turvata
            taloutesi pahimman varalta.
          </p>
          <p>
            Lähetämme sinulle pian vahvistusviestin, jossa saat
            yksityiskohtaiset tiedot vakuutuksestasi. Mikäli sinulla herää
            kysymyksiä liittyen uuteen Maksuturva-vakuutukseesi, älä epäröi
            ottaa yhteyttä meihin!
          </p>
        </>
      ),
      nb: (
        <>
          <p>
            Gratulerer med din nye utgiftsforsikring. Å forutse framtiden er
            vanskelig. Derfor er steget med å beskytte økonomien din et smart
            valg i tilfelle livssituasjonen din plutselig skulle endre seg.
          </p>
          <p>
            En epost med detaljer om forsikringen din vil bli sendt til deg
            innen 24 timer. Hvis du har noen spørsmål er det bare å ta kontakt
            med oss.
          </p>
        </>
      ),
    };
  },
  'Insurance policy number': () => {
    return {
      en: 'Insurance policy number',
      fi: 'Vakuutusnumero',
    };
  },
  'Insurance information': () => {
    return {
      en: 'Insurance information',
      fi: 'Vakuutuksen tiedot',
      nb: 'Forsikringsdetaljer',
    };
  },
  'Will be sent to you by email': () => {
    return {
      en: 'Will be sent to you by email',
      fi: 'Lähetämme sinulle pian vahvistusviestin, jossa saat yksityiskohtaiset tiedot vakuutuksestasi',
      nb: 'Eposten med forsikringsdeltajne vil inneholde polisenummeret knyttet til forsikringen din',
    };
  },

  //InsuranceLinks
  'Product Fact Sheet': () => {
    return {
      en: 'Product Fact Sheet',
      fi: 'Vakuutustietoasiakirja',
      nb: 'Produktinformasjon',
    };
  },
  'Pre-purchase Information and Terms and Conditions': () => {
    return {
      en: 'Pre-purchase Information and Terms and Conditions',
      fi: 'Etämyynnin ennakkotiedot ja täydelliset vakuutusehdot',
    };
  },
  'Pre-purchase Information': () => {
    return {
      en: 'Pre-purchase Information',
      fi: 'Etämyynnin ennakkotiedot',
      nb: 'Forhåndsinformasjon om forsikring',
    };
  },
  'Terms and Conditions': () => {
    return {
      en: 'Terms and Conditions',
      fi: 'Vakuutusehdot',
      nb: 'Fullstendige forskringsvilkår',
    };
  },
  'AvtaleGiro Info': (params: Params) => {
    return {
      en: (
        <>
          We are redirecting you to {params[0]} to complete the process. Please
          wait a few seconds, do not refresh or click the “Back” or “Close”
          button.
        </>
      ),
      nb: (
        <>
          Vi videresender deg til {params[0]} for å fortsette
          betalingsprosessen. Vennligst vent noen sekunder, og ikke last inn
          siden på nytt eller trykk "tilbake" eller "lukk vinduet".
        </>
      ),
    };
  },
  Here: () => {
    return { en: 'here', nb: 'her' };
  },
  'AvtaleGiro Contact and Support': (params: Params) => {
    return {
      en: (
        <>
          If you are not redirected, please click {params[0]} to proceed to{' '}
          {params[1]}, if something fails or you need further assistance please
          contact us through this phone number{' '}
          <a href={params[2] as string}>{params[2] as string}</a>
        </>
      ),
      nb: (
        <>
          Hvis du ikke blir videresendt, vennligst klikk {params[0]} for å
          fortsette til {params[1]}. Hvis noe fortsatt ikke fungerer eller du
          trenger mer assistanse, vennligst kontakt oss på tlf{' '}
          <a href={`tel:${params[2] as string}`}>{params[2] as string}</a>
        </>
      ),
    };
  },
  'Buy insurance': () => ({
    en: 'Buy insurance',
    fi: 'Osta vakuutus',
    nb: 'Kjøp forsikring',
  }),
  'Buy insurance and proceed to payment': () => {
    return {
      en: 'Buy insurance and proceed to payment',
      nb: 'Kjøp forsikring og gå til avtalegiro',
    };
  },
  'Address info': () => {
    return {
      en: 'To be able to sell you an insurance, we need you to fill out this information',
      nb: 'For å kjøpe denne forsikringen er du nødt til å fylle ut følgende informasjon',
    };
  },
  'Address street': () => {
    return {
      en: 'Street and number',
      nb: 'Gate og nummer',
    };
  },
  'Address city': () => {
    return {
      en: 'City',
      nb: 'By',
    };
  },
  'Address zip': () => {
    return {
      en: 'ZIP code',
      nb: 'Postkode',
    };
  },
  'Yes, continue with insurance': () => {
    return {
      en: 'Yes, continue with insurance',
      nb: 'Ja, gjør et nytt forsøk på å kjøpe forsikring',
    };
  },
  'An error occured': () => {
    return {
      en: 'An error occured, but we have saved your input for this session. Would you like to try again?',
    };
  },
  'Payment failed - headline': () => {
    return {
      en: 'Your payment has been cancelled',
      nb: 'Betaling kansellert',
    };
  },
  'Session expired - headline': () => {
    return {
      en: 'Your session has expired',
    };
  },
  'Something went wrong - headline': () => {
    return {
      en: 'Something went wrong',
      nb: 'Noe gikk galt',
    };
  },
  'Payment failed - body': () => {
    return {
      en: (
        <>
          <p>
            It seems that your payment process has been cancelled. We have saved
            your information, please double-check your payment details and try
            again.
          </p>
          <p>
            If you encounter any further issues during the payment process or
            have any questions, our customer support team is here to help, call
            us on {axoSupportPhoneNumber}
          </p>
        </>
      ),
      nb: (
        <>
          <p>
            Betalingsprosessen ble kansellert. Vi har midlertidig lagret din
            informasjon, vennligst dobbeltsjekk betalingsdetaljene dine og prøv
            igjen.
          </p>
          <p>
            Hvis du skulle møte på noen flere problemer under betalingsprosessen
            eller har andre spørsmål er vårt kundesenter tilgjengelig for hjelp.
            Telefon {axoSupportPhoneNumber}
          </p>
        </>
      ),
    };
  },
  'Session expired - body': () => {
    return {
      en: (
        <p>
          For security reasons, our system automatically logs you out after a
          period of inactivity. We have saved your information, click on “Try
          again” to reload the page to continue where you left off.
        </p>
      ),
    };
  },
  'Something went wrong - body': () => {
    return {
      en: (
        <>
          <p>
            We're sorry, but it seems like an error has occurred. Our team is
            already aware of the issue and is working to fix it as soon as
            possible. In the meantime, you can try refreshing the page or you
            can also skip this process and leave it for later
          </p>
          <p>
            If you encounter any further issues during the process or, our
            customer support team is here to help you, call us on{' '}
            {axoSupportPhoneNumber}.
          </p>
        </>
      ),
      nb: (
        <p>
          Noe gikk galt. Vennligst forsøk å laste siden på nytt. Hvis problemet
          fortsatt er der, trykk "prøv senere" ta kontakt med vårt kundeservice
          {axoSupportPhoneNumber} for å kjøpe forsikring.
        </p>
      ),
    };
  },
  'Refresh page': () => {
    return {
      en: 'Refresh page',
      nb: 'Last inn siden på nytt',
    };
  },
  'Try later': () => {
    return {
      en: 'Try later',
      nb: 'Prøv senere',
    };
  },
  'A safe choice': () => {
    return {
      en: 'A safe choice',
      nb: 'Et trygt valg',
    };
  },
  'Secure your finances': () => {
    return {
      en: 'Secure your finances',
      nb: 'Sikre økonomien din',
    };
  },
  'Explainer text': () => {
    return {
      en: 'Även om vi inte förvänter oss det kan sjukdom eller jobbförlust drabba vem som helst. Våra Trygghetsförsäkring bidrar till att täcka viktiga månatliga utgifter, och hjälper dig genom de tuffa situationerna. försäkringen består av tre delar:',
      nb: 'Även om vi inte förvänter oss det kan sjukdom eller jobbförlust drabba vem som helst. Våra Trygghetsförsäkring bidrar till att täcka viktiga månatliga utgifter, och hjälper dig genom de tuffa situationerna. försäkringen består av tre delar:',
    };
  },
  'Explainer - bullet point 1': () => {
    return {
      en: 'Erbjuder ett ekonomisk skydd vid ofrivillig arbetslöshet och sjukdom ',
      nb: 'Erbjuder ett ekonomisk skydd vid ofrivillig arbetslöshet och sjukdom ',
    };
  },
  'Explainer - bullet point 2': () => {
    return {
      en: 'Ger dig ett ekonomisk skydd vid allvarlig sjukdom ',
      nb: 'Ger dig ett ekonomisk skydd vid allvarlig sjukdom ',
    };
  },
  'Explainer - bullet point 3': () => {
    return {
      en: 'Ger dig ett engångsbelopp ved dödsfall till följd av olycka eller sjukdom ',
      nb: 'Ger dig ett engångsbelopp ved dödsfall till följd av olycka eller sjukdom ',
    };
  },
};
