/* eslint-disable axo-frontend/directory-structure */
import { loan_quote_presentation } from '@axo/shared/data-access/types';

import { createContext, useContext } from 'react';

const AcceptedQuoteContext = createContext<
  loan_quote_presentation.EnrichedLoanQuote | undefined
>(undefined);

type TAcceptedQuoteProviderProps = {
  acceptedQuote: loan_quote_presentation.EnrichedLoanQuote;
  children: React.ReactNode;
};

export const AcceptedQuoteContextProvider = ({
  acceptedQuote,
  children,
}: TAcceptedQuoteProviderProps) => {
  return (
    <AcceptedQuoteContext.Provider value={acceptedQuote}>
      {children}
    </AcceptedQuoteContext.Provider>
  );
};

export const useAcceptedQuote = () => {
  const context = useContext(AcceptedQuoteContext);
  if (context === undefined) {
    return {
      Amount: undefined,
      Lender: {
        Name: undefined,
      },
    };
  }
  return context;
};
