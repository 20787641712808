import { Icons } from '@axo/deprecated/util/ui-components';
import { IPayOutAndPremium } from '@axo/insurance/const/no';
import { useState } from 'react';
import { currencyFormatter } from '../../currency';
import { useTranslation } from '../../translations/useTranslation';
import AmountSelectionMenu from './AmountSelectionMenu';
import EditButton from './EditButton';
import styles from './SelectCoverageDropdown.module.scss';

export const SelectCoverageDropdown = ({
  onChange,
  payout: { payout },
}: {
  onChange: (v: IPayOutAndPremium | null) => void;
  payout: { payout: number; premium: number };
}) => {
  const { t } = useTranslation();
  const [isAmountSelectionVisible, setIsAmountSelectionVisible] =
    useState(false);

  return (
    <>
      <div className={styles.content}>
        <ul>
          <li className={styles.listContent}>
            <Icons.CircleCheckMarkValid size="sm" />
            <div style={{ display: 'flex', gap: 'var(--padding-small-2' }}>
              <div style={{ display: 'inline' }}>
                <span style={{ fontWeight: 'bold', color: 'var(--info-700)' }}>
                  {currencyFormatter(payout)}/{t('Month')}
                </span>{' '}
                {t('in compensation')}
              </div>
              <EditButton onClick={() => setIsAmountSelectionVisible(true)} />
            </div>
          </li>
          <li className={styles.listContent}>
            <Icons.CircleCheckMarkValid size="sm" />
            {t('The money is paid directly to your account')}
          </li>
          <li className={styles.listContent}>
            <Icons.CircleCheckMarkValid size="sm" />
            {t(
              'Contributes to cover expenses in the event of illness and unemployment'
            )}
          </li>
          <li className={styles.listContent}>
            <Icons.CircleCheckMarkValid size="sm" />
            {t('Compensation for up to 12 months')}
          </li>
        </ul>
      </div>
      <h4>{t('Secure your finances')}</h4>
      <p>
        {t(
          'A security insurance can be a security for you should changes occur in your life situation'
        )}
      </p>
      {isAmountSelectionVisible && (
        <AmountSelectionMenu
          onChange={(value: IPayOutAndPremium | null) => {
            setIsAmountSelectionVisible(false);
            onChange(value);
          }}
        />
      )}
    </>
  );
};
