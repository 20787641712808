import { ReactElement } from 'react';

export enum Languages {
  en = 'en',
  fi = 'fi',
  nb = 'nb',
  dk = 'dk',
}

type TranslationResult = string | ReactElement;
export type Params = TranslationResult[];

// Type for each record in the translator object.
type Translator<Props> = (params: Props) => {
  [Language in Languages]?: TranslationResult;
};

// Creates a type for each record in the translations object. The keys a the union of the keys in the
// passed in object, and the type is the translator object with the prop types set by the second type parameter.
type TranslationMap = {
  [Name: string]: Translator<Params>;
};

type TranslationNotFound<Key extends string> =
  `[TRANSLATION NOT FOUND: ${Key}]`;

type KeyLangTranslationType<
  TranslationsObject extends TranslationMap,
  Key extends keyof TranslationsObject & string,
  Lang extends keyof ReturnType<TranslationsObject[keyof TranslationsObject]>
> = ReturnType<TranslationsObject[Key]> extends Record<
  Lang,
  infer TranslationType
>
  ? TranslationType
  : Key | TranslationNotFound<Key>;

type TranslationProps<TranslationObject> = {
  translations: TranslationObject;
  preferredLanguage: Languages;
  defaultLanguage?: Languages;
  showDebugErrors?: boolean;
};

export function getTranslation<
  TranslationsObject extends TranslationMap,
  Lang extends Languages
>({
  translations,
  preferredLanguage,
  defaultLanguage = Languages.en,
  showDebugErrors = false,
}: TranslationProps<TranslationsObject>) {
  function translate<Key extends keyof TranslationsObject & string>(
    key: Key,
    params?: TranslationResult[]
  ): KeyLangTranslationType<TranslationsObject, Key, Lang> {
    const translationConfig = translations[key]?.(params || []);
    let result: TranslationResult = key;

    if (translationConfig) {
      result =
        translationConfig[preferredLanguage] ??
        translationConfig[defaultLanguage] ??
        key;
    } else if (showDebugErrors) {
      result = `[TRANSLATION NOT FOUND: ${key}]`;
    }

    return result as KeyLangTranslationType<TranslationsObject, Key, Lang>;
  }

  return { t: translate };
}
