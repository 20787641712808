/* eslint-disable axo-frontend/directory-structure */
import { Icons } from '@axo/deprecated/util/ui-components';
import { useTranslation } from '../../translations/useTranslation';

import { MyPageFlags } from '@axo/mypage/util';
import { useFlags } from '@axo/shared/services/feature-flags';
import styles from './SelectCoverageTitle.module.scss';

export const SelectCoverageTitle = () => {
  const { t } = useTranslation();

  const { 'new-insurance-flow-v2': flag_new_insurance_flow_v2 } =
    useFlags(MyPageFlags);

  if (!flag_new_insurance_flow_v2) return t('Product details');

  return (
    <div className={styles.selectCoverageTitleContainer}>
      <Icons.Shield size="lg" />
      <span className={styles.title}>{t('Axo insurance')}</span>
    </div>
  );
};
